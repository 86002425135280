
<template>


  <!-- <transition name="el-fade-in">
    <div class="drawer-mask" v-show="visible">
      <div class="drawer-container">
        <transition name="drawer">
          <div class="drawer-content" v-show="visible">
            <div class="drawer-header">
              <h2 class="header-title">审核记录</h2>
            </div> -->
<HqDrawer  title="审核记录" :visible.sync="showVissible" ref="drawer" size="90%">
            <div class="drawer-body">
              <div class="goods-table-wrapper">
                <!-- 记录表格区 -->
                <el-table
                  style="width: 100%;margin-bottom:24px"
                  border
                  stripe
                  size="mini"
                  element-loading-text="拼命加载中"
                  :header-cell-style="{ background: '#e5f2ff' }"
                  sortable
                  highlight-current-row
                  header-cell-class-name="normal-table-header"
                  :data="tableData"
                  :row-key="(row) => row.orderId"
                   ref="multipleTable"
                  @selection-change="onSelectChange"
                  @current-change="selOrder"
                >
                  <template v-for="item of columns">
                    <el-table-column :show-overflow-tooltip="true" v-if="item.prop === 'createTime'" :label="item.label" :key="item.prop" min-width="180px">
                      <template v-slot:default="{ row }">
                        {{ row.createTime | formatDate('YYYY-MM-DD HH:mm:ss')}}
                      </template>
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'processTime'" :label="item.label" :key="item.prop" min-width="180px">
                      <template v-slot:default="{ row }">
                       <span v-if="row.processTime!= '- -'">{{ row.processTime | formatDate('YYYY-MM-DD HH:mm:ss') }}</span>
                       <span v-else>- -</span>
                      </template>
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'processStatus'" :key="item.prop" :label="item.label" min-width="160px">
                      <template v-slot:default="{ row }">
                        {{ auditMap[row.processStatus] }}
                      </template>
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'nowApproverName'" :key="item.prop" :label="item.label" min-width="160px">
                      <template v-slot:default="{ row }">
                        <span v-if="row.nowApproverName === ''">- -</span>
                        <span v-else>{{row.nowApproverName}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true"
                      v-else
                      :key="item.label"
                      :label="item.label"
                      :prop="item.prop"
                      :fixed="item.fixed"
                      :width="item.width || '170px'"
                    >
                      <template v-slot:default="{ row }">
                        <span v-if="['remark'].includes(item.prop)">{{ row[item.prop] | empty('- -')}}</span>
                        <span v-else>{{row[item.prop]}}</span>
                      </template>
                    </el-table-column>
                  </template>
                </el-table>
                <Pagination
                  :page.sync="page"
                  :pageSize.sync="pageSize"
                  :totalPage="totalPage"
                  :totalCount="totalCount"
                  @pageChange="pageChange"
                  @pageSizeChange="pageSizeChange"
                />
              </div>
            </div>
            
            <div slot="footer">
              <el-button @click="onCancel">返回</el-button>
            </div>
</HqDrawer>
          <!-- </div>
        </transition>
      </div>
    </div>
  </transition> -->
</template>

<script>
import { auditMap } from '../constants'
import Pagination from './pagination.vue'
export default {
  name: "ReviewHistory",
  props: {
    visible: Boolean,
    aftersaleType: [Number, String],
    reviewData: Object,
    orderModalRules: Object,
    orderModalItems: Array,
    // selectMode 0 单选 1 多选
    selectMode: String
  },
  components: {
    Pagination
  },
  data() {
    return {
      showVissible: this.visible,
      currentRadioIndex: "",
      tableData: [],
      columns: [
        { label: "提审时间", prop: "createTime", width: "120px", fixed: true },
        { label: "钉钉编号", prop: "processInstanceCode", width: "170px", fixed: true },
        { label: "审核状态", prop: "operation",width: "80px", fixed: true  },
        { label: "审核人", prop: "nowApproverName",  },
        { label: "审核时间", prop: "processTime" },
        { label: "审核意见", prop: "remark" },
      ],
      page: 1,
      pageSize: 10,
      totalPage: 1,
      totalCount: 1,
      goodsCateModalVisible: false,
      currentRow: {},
      selectRows: [],
      auditMap,
      id: '',
      processInstanceId: ''
    }
  },
  methods: {
    init(row) {
      this.$refs.drawer.init()
      this.id = row.id
      this.processInstanceId = row.processInstanceId ? row.processInstanceId : ''
      this.getTableData()
    },
    onCancel() {
      // this.$emit("on-cancel")
      this.$refs.drawer.close()
    },
   
    onRadioChange(index, row) {
      this.currentRadioIndex = index
      this.currentRow = row
    },
    selOrder(row){
      console.log("selOrder",row)
      this.currentRow = row
    },
    onSelectChange(rows) {
      console.log("onSelectChange",rows)
      this.selectRows = rows
    },
    pageChange() {
      this.currentRadioIndex = ""
      this.getTableData()
    },
    pageSizeChange() {
      this.currentRadioIndex = ""
      this.page = 1
      this.getTableData()
    },

    // 获取订单列表表格数据
    async getTableData() {
      this.tableData = []
      const params = {
        // afterSaleId:"1453",
        // afterSaleId:this.reviewData.id,
        processInstanceId: this.processInstanceId,
        afterSaleId: this.id,
        curPage: this.page,
        pageSize: this.pageSize,
      }

      console.log("表格的参数",params);
      const { data = {records: [], page: 1, total: 0}, code } = await this.$fetch("findProcessRecordById", params) 
      let tmp = {
        nowApproverName:"- -",
        processTime:"- -"
      }
      console.log(data)
      // data.records[0].nowApproverName = "111"
      this.tableData = data&&data.records?data.records.map(item => (Object.assign({}, tmp, item)  )):[]
      // this.tableData = data.records.map(item => ({ ...item, checked: false }))
      this.totalPage = data.pages
      this.totalCount = data.total
    },
     // 表格换页多选包含之前页面选中的数据
    getRowKeys(row) {
        return row.id   //raceid为列表数据的唯一标识
    },
  },
  computed: {
    isCurrentRow() {
      if (!this.currentRow) return false
      return !!Object.keys(this.currentRow).length || !!this.selectRows.length
    },
  },
  watch: {
    visible: {
      handler(visible) {
        this.showVissible = visible;
        if (!visible) return
        this.getTableData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.goods-table-wrapper {
    // width: 100%;
    width: 94%;
    margin: 0 auto;
    // margin-bottom: 44px;
    // padding: 0 30px;
    // margin-top: 10px;
  }
.drawer-mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 100;
  background: rgba($color: #000000, $alpha: 0.6);
  .drawer-container {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    .drawer-content {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      width: 72%;
      height: 100%;
      background: #fff;
      box-shadow: -6px 0px 10px 0px rgba(112, 112, 112, 0.12);
      .drawer-header {
        display: flex;
        width: 100%;
        height: 60px;
        line-height: 60px;
        padding: 0 30px;

        > h2 {
          color: #333;
          font-size: 16px;
          font-weight: 700;
        }
      }
      .drawer-body {
        flex: 1;
        border-top: 1px solid #e1e4eb;
        border-bottom: 1px solid #e1e4eb;
        overflow: auto;
        .goods-form-wrapper {
          margin: 30px 0 0px 0;
          padding: 0 25px;
          .formContent{
            display: flex;
            flex-wrap: wrap;
          }
          .searchItem{
            display: flex;
            width: 300px;
          }
          ::v-deep .el-input__suffix {
            right: 12px;
          }
        }
        .actions-btn {
          padding-left: 68px;
        }
        .goods-table-wrapper {
          // width: 100%;
          width: 94%;
          margin: 0 auto;
          // margin-bottom: 44px;
          // padding: 0 30px;
          // margin-top: 10px;
          .el-radio__label{
            display: none;
          }
        }
      }
      
    }
  }
}
.drawer-footer {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // width: 100%;
  // height: 90px;
  position: absolute;
  bottom: 24px;
  left: 0;
  text-align: center;
  width: 100%;
}
.drawer-enter-active,
.drawer-leave-active {
  transition: 0.3s;
}
.drawer-enter {
  transform: translate(100%);
}
.drawer-leave-to {
  transform: translate(100%);
}
</style>
